body {
	font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6
{
	font-family: 'Open Sans', sans-serif;
}

h3 {
	font-size: 2.5rem;
}


	#header h1 {
		float: left;

		padding: 0.5em 0;
		margin: 0;
	}

	#header .address {
		font-size: 5rem;
		height: 5rem;
		padding: 0.5em 0;
	}

		#header .address p {
			font-size: 12pt;
			line-height: 1.2em;
			text-align: right;
		}


	#nav ul {
		list-style-image: none;
		margin: 0;
		padding: 0;
		text-indent: 0;
	}

	#nav ul li {
		display: block;
		text-align: center;
		margin: 0;
		padding: 0;

		float: left;

		font-size: 90%;
	}

	#nav ul .active {
		background: rgba(0,0,0,0.2);
	}
		
		#nav ul li a {
			display: block;

			font-size: 150%;
			padding: 0.5em 1em;

			text-decoration: none;
		}

	#hero img {
		max-width: 100%;
	}

#main-content, #sidebar {
	padding-top: 2em;
}

#main-content > img,
#main-content p img{
	max-width: 100%;
	height: auto;
}

	#content #main-content {

		padding-right: 1em;
	}

#sidebar {
	padding-right: 2em;
}

	#sidebar .g-recaptcha {
		transform: scale(0.88);
		transform-origin: 0 0;
	}

#content #main-content {
	padding-bottom: 3em;	
}

#footer {
	padding-bottom: 3em;
}
	
	#footer .container .eight {
		margin-left: 0;
	}

	#footer ul, #footer li {
		list-style: none;

	}

	#footer h1,
	#footer h2,
	#footer h3,
	#footer h4,
	#footer h5,
	#footer h6 {
		font-family: inherit;
		font-size: 100%;
	}

	#footer #footer-subline {
		padding: 3em 0;
	}

@media (max-width: 550px) {
	#content #content-background {
		padding: 0;
		width: auto;
	}

	#content #main-content,
	#content #sidebar {
		padding: 3em 2em;
	}

	#nav ul li {
		width: 50%;
		display: block;
/*		display: inline-block;*/
		float: left;
	}

	#header .address {
		padding-top: 0;
	}

	#header h1 {
		padding-bottom: 0;
	}

	#content img {
		display: block;
		float: none !important;
		width: 100%;
		height: auto;
		padding-bottom: 1em;
	}
}

.cms-error {
	clear: both;
	margin-top: 1em;
	background: indianred;
	color: #FFFFFF;
	padding: 1em;
	border-radius: 0.5em;
}

	#main-content .cms-error h3 {
		color: #FFFFFF !important;
	}

	.cms-error p {
		margin-bottom: 0;
	}

ul, ol {
    padding-left: 2em;
}

li {
    margin-bottom: 0;
}